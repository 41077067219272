import { Platform, Image as RNImage } from 'react-native'

export const getImageDimensions = async (url) => {
    return new Promise((resolve, reject) => {
        if (Platform.OS === 'web') {
            const img = new Image()
            img.src = url
            img.onload = () => resolve({ width: img.width, height: img.height })
            img.onerror = (error) => reject(error)
        } else {
            RNImage.getSize(
                url,
                (width, height) => {
                    resolve({ width, height })
                },
                (error) => {
                    reject(error)
                }
            )
        }
    })
}

export const getAspectRatio = (width, height) => {
    if (!height || height === 0) {
        return 1
    }
    return width / height
}

export const getImageAspectRatio = async (url) => {
    try {
        const dimensions = await getImageDimensions(url)
        if (dimensions && typeof dimensions === 'object' && 'width' in dimensions && 'height' in dimensions) {
            return getAspectRatio(dimensions.width, dimensions.height)
        }
        return 1
    } catch (error) {
        console.error('Error getting image aspect ratio:', error)
        return 1
    }
}

export const calculateImageHeight = (
    containerWidth,
    imageWidth,
    imageHeight
) => {
    if (!containerWidth || !imageWidth || !imageHeight) {
        return 0
    }

    const aspectRatio = getAspectRatio(imageWidth, imageHeight)

    return containerWidth / aspectRatio
} 