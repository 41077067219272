import { Text, WatchLaterIcon, XStack } from '@bp/ui'

export default function LegacyPostMessage() {
  return (
    <XStack alignItems="center" justifyContent="center" padding="$2" gap="$2">
      <WatchLaterIcon size="$1" color="$warningDarker" />
      <Text centered>This is a legacy post. Comments and likes are locked.</Text>
    </XStack>
  )
}
