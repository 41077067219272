import React, { useEffect, useState } from 'react'
import { View, Image, StyleSheet, ImageStyle } from 'react-native'
import { getImageAspectRatio } from 'app/utils/image'

export function FlexibleImage({ url, ...rest }) {
  const [aspectRatio, setAspectRatio] = useState(1)

  useEffect(() => {
    if (url) {
      const loadAspectRatio = async () => {
        const ratio = await getImageAspectRatio(url)
        setAspectRatio(ratio)
      }

      loadAspectRatio()
    }
  }, [url])

  const dimensions: { width?: number; height?: number } = {}
  if (rest.width) {
    dimensions.width = rest.width
  }
  if (rest.height) {
    dimensions.height = rest.height
  }
  const isCustom = rest.width || rest.height

  return (
    <View
      style={{
        ...styles.container,
        width: isCustom ? undefined : '100%',
        flex: isCustom ? undefined : 1,
      }}
    >
      <Image
        source={{ uri: url }}
        style={{
          ...styles.image,
          ...dimensions,
          aspectRatio: isCustom ? undefined : aspectRatio
        }}
        resizeMode={isCustom ? 'cover' : 'contain'}
        {...rest}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: undefined,
    // aspectRatio is now set dynamically based on the image
  },
})
