import { Button, YStack, Text, ExclamationCircleIcon } from '@bp/ui'
import Sentry from 'app/sentry'

/*
    GracefulError types:
    hide: Hide the broken component, e.g. hide the post. Use this type to wrap a component.
    placeholder: Show a placeholder, e.g. show placeholder for a broken image, don't hide it. Use this type inside a component or outside, with extra style props.
    *report: placeholder + report button to open dialog. Use this type in important places.
    *Builreport dialog is not working right now, we might need to update some dependencies, not for Q1.
*/

/*
    GracefulError strategies:
    Post - hide the post if it's broken?
    Feed - show a placeholder if the whole Feed is broken.
    TBD

    <GracefulError type="placeholder" marginBottom="$4" borderRadius={8}>
        <Post post={post} userId={userId} feedSlug={feedSlug} />
    </GracefulError>
*/

function GracefulError({
  children,
  type = 'hide',
  placeholder = null,
  hideInfo = false,
  hideReset = false,
  ...rest
}) {
  let fallback

  const reportError = ({ eventId }) => {
    // TODO: Doesn't work right now, we might need to update some dependencies, not for Q1.
    Sentry.showReportDialog({ eventId })
  }

  // We should probably not use hide for now, we want to see the errors on screen.
  // TODO: Remove for Q1 launch?
  // Those will still be logged in Sentry.
  if (type === 'hide') {
    fallback = () => {
      return null
    }
  }

  if (type === 'placeholder' || type === 'report') {
    fallback = ({ error, resetError, eventId, componentStack }) => {
      // For now we want the errors to be very visible, so a red background is used.
      return (
        <YStack
          flex={1}
          justifyContent="center"
          alignItems="center"
          gap="$2"
          backgroundColor="$error"
          padding="$3"
          {...rest}
        >
          <ExclamationCircleIcon />
          <Text centered fontWeight="bold">
            An error occurred in this component
          </Text>
          {!hideInfo && <Text>Error: {error.message}</Text>}
          {!hideInfo && <Text>ID: {eventId}</Text>}
          {!hideReset && (
            <Button marginTop="$2" variant="dangerOutlined" onPress={resetError}>
              Reset the component (might work)
            </Button>
          )}
          {type === 'report' && (
            <Button marginTop="$2" variant="dangerOutlined" onPress={reportError}>
              Report this error with extra information
            </Button>
          )}
        </YStack>
      )
    }
  }

  if (placeholder) {
    if (typeof placeholder === 'string') {
      fallback = () => {
        return <YStack flex={1} justifyContent="center" alignItems="center" gap="$2" padding="$4" backgroundColor="$lightest" borderRadius={8}><Text>{placeholder}</Text></YStack>
      }
    } else {
      fallback = placeholder
    }
  }

  return (
    <Sentry.ErrorBoundary showDialog={true} fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export function withGracefulError(
  Component,
  errorOptions = {}
) {
  const defaultOptions = { type: 'hide', hideInfo: false, hideReset: false }
  const options = { ...defaultOptions, ...errorOptions }

  const WrappedComponent = (props) => {
    return (
      <GracefulError
        {...options}
      >
        <Component {...props} />
      </GracefulError>
    )
  }

  const componentName = Component.displayName || Component.name || 'Component'
  WrappedComponent.displayName = `withGracefulError(${componentName})`

  return WrappedComponent
}

export default GracefulError
