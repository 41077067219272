import { Text } from '@bp/ui'
import { Fragment } from 'react'
import { TextLink, Link } from 'solito/link'

const escapeRegex = (str) => {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
}

export function parseBody(text, linksArray = []) {
  if (linksArray.length === 0) return text

  const escapedLinks = linksArray.map((link) => escapeRegex(link))
  const linkPattern = new RegExp(escapedLinks.join('|'), 'g')

  const parts = text.split(linkPattern)
  const matches = text.match(linkPattern)

  return parts.map((part, index) => {
    if (index < parts.length - 1) {
      return (
        <Fragment key={index}>
          <Text>{part}</Text>
          <TextLink
            href={matches[index]}
            target="_blank"
            textProps={{ style: { color: 'blue', fontSize: 14 } }}
          >
            {matches[index]}
          </TextLink>
        </Fragment>
      )
    }
    return <Text key={index}>{part}</Text>
  })
}
