import { YStack, Text, XStack, FlexibleImage } from '@bp/ui'
import { Link } from 'solito/link'
import { withErrorBoundary } from 'react-error-boundary'

export function convertGetStreamOgFetchToEmbedly(gs) {
  if (!gs) return {}
  if (gs?.error) return gs

  const images = [];

  if (gs.images && Array.isArray(gs.images)) {
    for (let i = 0; i < gs.images.length; i++) {
      const img = gs.images[i];
      if (img && (img.url || img.image)) {
        images.push({
          url: img.url || img.image,
          height: parseInt(String(img.height)) || 0,
          width: parseInt(String(img.width)) || 0,
        });
      }
    }
  }

  return {
    title: gs.title || '',
    description: gs.description || '',
    url: gs.url || gs.original_url || '',
    images: images,
    provider: {
      faviconUrl: gs.favicon || gs.provider?.faviconUrl || null,
      name: gs.site_name || gs.provider?.name || '',
    },
  }
}

function getDomain(url) {
  try {
    return new URL(url).hostname
  } catch (e) {
    return null
  }
}

function getFavicon(url) {
  try {
    const hostname = new URL(url).hostname
    const parts = hostname.split('.')

    // Extract the top-level domain (example.com, example.co.uk)
    const tld =
      parts.length > 2 && parts[parts.length - 2].length <= 3
        ? parts.slice(-3).join('.')
        : parts.slice(-2).join('.')

    return `https://${tld}/favicon.ico`
  } catch (e) {
    return null
  }
}

function URLPreviewCard({ title, url, images, provider, error }) {
  if (error) return <PreviewUrlError error={error} />
  if (!url) return null

  const domain = getDomain(url)
  const favicon = provider?.faviconUrl || getFavicon(url)
  const siteName = provider?.name || domain

  // Find the best image: prioritize larger images
  const image = images && images.length > 0
    ? images.sort((a, b) => {
      const aSize = (a.width || 0) * (a.height || 0);
      const bSize = (b.width || 0) * (b.height || 0);
      return bSize - aSize;
    })[0]
    : null;

  const topLevelDomain = getDomain(url)

  // Check if the image is large enough to display prominently
  const isLargeImage = image && image.width > 300 && image.height > 200;

  return (
    <Link href={url}>
      <YStack elevation={5} backgroundColor="$light" gap="$3" padding="$4" borderRadius="$4">
        {image && (
          <FlexibleImage
            url={image.url}
            height={isLargeImage ? Math.min(image.height, 300) : undefined}
          />
        )}
        {(title || siteName) && (
          <XStack gap="$2">
            {favicon && <FlexibleImage url={favicon} width={16} height={16} />}
            <Text textTransform='uppercase' fontSize='$4'>
              {topLevelDomain}
            </Text>
          </XStack>
        )}
        <Text fontWeight='bold' fontSize='$7'>{title || siteName}</Text>
      </YStack>
    </Link>
  )
}

const PreviewUrlError = ({ error = 'Preview not available' }) => (
  <YStack elevation={5} backgroundColor="$lighter" gap="$3" padding="$6" borderRadius="$4">
    <Text fontWeight="bold" fontSize="$4">Preview not available</Text>
    <Text>{error}</Text>
  </YStack>
)

export default withErrorBoundary(URLPreviewCard, {
  fallback: <PreviewUrlError />,
})
